import React from "react";
import { ParentGuardianInformation } from "./ParentGuardian";
// import MultiStep from "react-multistep";
// import { GeneralInformation } from "./GeneralInfromation ";
// import { EmergencyInformation } from "./EmergencyInformation";
// import { StudentEmploymentInformation } from "./StudentEmployment";
// import { FamilyReference } from "./FamilyReference";
// import { GuarantorInformation } from "./GuarantorInformation";
// import { SignatureArea } from "./SignatureArea";
// import { ResidentInformation } from "./ResidentInformation";

export default function ApplicationForm() {
  return (
    <div>
      {/* <GuarantorInformation /> */}
      {/* <GeneralInformation /> */}
      {/* <FamilyReference /> */}
      {/* <SignatureArea /> */}
      <ParentGuardianInformation />
      {/* <EmergencyInformation /> */}
      {/* <StudentEmploymentInformation /> */}
      {/* <ResidentInformation /> */}
    </div>
  );
}
