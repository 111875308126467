import { boolean, object, ref, string } from "yup";

export const initalValues = {
  firstName: "",
  lastName: "",
  email: "",
  telephone: "",
  property: "",
  room: "",
  password: "",
  confirmPassword: "",
  agreeToRules: false,
  agreementConfirmed: false,
};

export const registerSchema = object().shape({
  firstName: string().required("First Name is required"),
  lastName: string().required("Last Name is required"),
  email: string()
    .trim("")
    .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Email is incorrectly typed")
    .required("Email is Required"),
  telephone: string()
    .matches(
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
      "Please enter a valid Phone Number"
    )
    .required("Please enter a Phone Number"),
  password: string()
    .required("No password provided.")
    .min(8, "Password is too short - should be 8 characters minimum.")
    .matches(/[a-zA-Z]/, "Password should have upper or lowercase letters."),
  property: string().required("Property is Required"),
  room: string().required("Room Code is Required"),
  confirmPassword: string()
    .required("Please confirm password")
    .oneOf([ref("password")], "Passwords do not match"),

  agreeToRules: boolean().oneOf(
    [true],
    "Please confirm that you have read and understood the rules"
  ),
  agreementConfirmed: boolean().oneOf(
    [true],
    "Please confirm that you have read and understood the Terms of Agreement"
  ),
});

//Agreement List
export const agreementList = [
  "You are required to respect the boundary areas and privacy of each person.",
  "The Landlords insurance does not cover personal losses. Boarders should take precautions to protect personal belongings from theft, fire, water damage, or other loss. Individual insurance policies protecting personal possessions are the sole responsibility of the boarder, should you wish to provide for such contingencies",
  "Allow Zenith Management staff to check the room quarterly.",
  "Cooking in the room is prohibited and should only be done in the kitchen area ",
  "The display of sexual behaviour that would be reasonably offensive to others is strictly prohibited; including conduct that creates an intimidating, hostile/offensive environment for another person",
  "No pets are allowed on the premises in any form",
  "Do not take or consume other people’s food or beverages",
  "All trash and garbage must be emptied regularly prior to creating an unsanitary condition.",
  "No microwaves/toaster ovens, refrigerators or hot plates are allowed in rooms.",
  "Smoking of any kind including tobacco and cigarettes are strictly prohibited on the property.",
  "The use or possession of illegal drugs, narcotics, or hallucinatory agents on the property is prohibited. Any PERSON suspected of possessing or selling drugs or narcotics, possession, or sale of drugs or narcotics shall be disciplined according to the law and evicted from housing. Any drug residue or paraphernalia found in rooms is also sufficient evidence for eviction/legal action.",
  "Be considerate. Please do not turn your TV or stereo volume too loud so as to affect the wellbeing of your neighbors. Excessive noise is strictly prohibited!!",
  "Respect the rest of others – DAY or NIGHT!",
  "All occupants should be tolerant, considerate and respect each other at all times. There should be no use of vulgar and abusive language and use of force (fighting) or any unruly behavior towards one another. First offence of this may warrant an eviction.",
  "As best as possible all occupants should avoid “overcrowding” their rooms. No visitors are allowed to stay beyond MIDNIGHT without the expressed knowledge of the property manager",
  "Tenants will neither use nor permit the use of their residential spaces for any purpose other than their own private dwelling",
  "Flammable liquids such as candles, gasoline, turpentine, or oils will not be allowed in rooms.",
  "Nailing, drilling or any fittings to the walls, ceilings or any part of the room is strictly prohibited. The resident(s) of the room shall be responsible for any damage caused.",
  "Accessories including bedskirts, mattress protectors and curtains must remain in the room and maintained in fair condition without damage.",
  "Any problems concerning your stay must be promptly communicated to the property manager in writing at zenithboarding@gmail.com including other matters on all repairs and maintenance work, please report to the property manager.",
  "Theft of any kind or possession of stolen property is strictly prohibited.",
  "The toilets and showers are for tenants/ occupants ONLY! Please keep facilities clean.",
  "Any actions that may cause damages to the property such as removal, alteration or modification of furniture are strictly forbidden. Violators shall be fined in accordance with such property’s value for any damage incurred to effect its repair/ replacement.",
  "Bulbs should not be removed from their location without the knowledge of the landlord. In the event of blown or defective lighting, items should be replaced by the resident.",
  "30 days’ notice MUST be given and all bills settled for the full refund of security deposits.",
  "Rent is due on the 25th of each month. Late fees will apply for payments received after the 1st.",
  "Utility bills must be paid punctually. Utility company equivalent late & disconnection fees apply.",
  "This agreement subsists for you to lease occupancy of room # ______ for the period of August 24 2021 – May 24 2022. Early termination of the agreed 38 week boarding term at no fault of the landlord will result in deposits deemed non-refundable.",
  "Utilities included in the boarding fees are Android Cable and internet only. Zenith Boarding accepts no liabilities for failures in these services due to the service providers.",
  "Security deposits for students terminating at the end of the boarding year will be refunded by request on May 28th, 2021. After room inspections are completed and all bills settled in full",
  "Residents shall abide by any concerns or warnings of the property manager.",
  "Your Room Key is issued and controlled by YOU the tenant. Loss or damage to said key is done with you the tenant bearing full replacement cost/access costs. NO KEY SHALL BE COPIED WITHOUT THE EXPRESSED KNOWLEDGE OF THE PROPERT MANAGER.",
  "Access fee of $1,000 will be charged to open locked rooms with no fault to locking mechanisms.",
  "Upon tenancy termination keys must be returned to our office ONLY and signed as returned.",
  "Surveillance Systems may be installed in the common areas. Zenith boarding reserves the right to use camera footage for the maintenance of the house regulations & protection of its residents.",
  "Zenith Boarding reserves the right to implement emergency evacuation of the premises in a state of emergency or other situation where life may be threatened.",
  "Any personal items left at the property after 7days of evacuation without a request for storage being made, will be considered abandoned and will be discarded.",
  "Rooms left without occupancy or payment beyond 28 days will be considered abandoned and items left are subject to removal without storage",
  "Organized or spontaneous social activity (exceeding 5 persons) must be cleared with the property manager",
  "It is strictly prohibited for residents and guests to sleep in common areas/couches overnight.",
  "Serious or disruptive behaviour that interferes with normal household operation will not be tolerated and may result in immediate dismissal/eviction with no prior notice.",
];
