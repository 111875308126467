import React, { useState } from "react";
import { Alert, Button, Card, Col, Jumbotron, Row } from "reactstrap";
import PayBills from "./components/PayBills";
import "./Dashboard.css";
import jps_logo from "./../../assets/img/jps-logo.png";
import nwc_logo from "./../../assets/img/nwc-logo.jpg";
import digi_logo from "./../../assets/img/digicel-logo-vector.png";
import flow_logo from "./../../assets/img/flow-logo.png";
import ReportIssue from "./ReportIssue";
import UploadReceipt from "./UploadReceipt";
import Status from "./Status";
import { useSelector } from "react-redux";
import {
  getBillDetails,
  getPropertyDetails,
  getRoomDetails,
  getBroadcastMessage,
} from "../../globals/student";
import { formatDateToLocal } from "./functions";

export default function Dashboard() {
  const [activeTab, setActiveTab] = useState("");
  const propertyDetails = useSelector(getPropertyDetails);
  const broadcastMessage = useSelector(getBroadcastMessage); //eslint-disable-line
  const bills = useSelector(getBillDetails);
  const [open, setOpen] = useState(true);
  const roomDetails = useSelector(getRoomDetails);
  const jpsBill = bills.find((bill) => bill.bill_name === "JPS");
  const nwcBill = bills.find((bill) => bill.bill_name === "NWC");
  const internetBill = bills.find((bill) => bill.bill_name === "Internet");
  const split = bills.find((el) => el?.split)?.split?.toString();

  const FlasMessage = ({ message, date }) => {
    const dismissAlert = () => {
      setOpen(false);
    };
    return (
      <div className="mx-auto">
        <Alert
          color="warning p-3 mt-3 fade"
          isOpen={open}
          toggle={dismissAlert}
        >
          <h4 className="alert-heading">Notice!</h4>
          {/* <p>{broadcastMessage?.message}</p> */}
          <p>{message}</p>
          <hr />
          <p className="mb-0 text-right">
            {/* {formatDateToLocal(new Date(broadcastMessage?.date_sent))} */}
            <strong> Broadcast Date</strong>: {formatDateToLocal(date)}
          </p>
        </Alert>
      </div>
    );
  };
  return (
    <Row className="dashboard ">
      <Row className="mt-3 mx-auto ">
        <Col className="col-md-12 p-0 m-0">
          <Jumbotron
            style={{
              backgroundColor: "",
            }}
            className="umbotron-fluid"
          >
            <Row className="p-3">
              <Col md={6} sm={12} xs={12}>
                <h1
                  className="display-4"
                  style={{ color: "#0075c1", fontWeight: 600 }}
                >
                  Welcome to the Student Dashboard
                </h1>
                <p className="lead">
                  This is your dashboard, where you can see your bills,
                  payments, and other details.
                </p>
              </Col>
              <Col md={6} sm={12} xs={12} className="text-center">
                <Row className="my-auto">
                  <Col md={12} className="mb-3">
                    <Button
                      className="custom-button-tab"
                      onClick={() => setActiveTab("Pay Bills")}
                      style={{
                        backgroundColor:
                          activeTab === "Pay Bills" ? "#0075c1" : "#fff",
                        color: activeTab === "Pay Bills" ? "#fff" : "#0075c1",
                      }}
                    >
                      Pay Bills
                    </Button>
                  </Col>

                  <Col md={12} className="mb-3">
                    <Button
                      className="custom-button-tab"
                      onClick={() => setActiveTab("Report Issue")}
                      style={{
                        backgroundColor:
                          activeTab === "Report Issue" ? "#0075c1" : "#fff",
                        color:
                          activeTab === "Report Issue" ? "#fff" : "#0075c1",
                      }}
                    >
                      Report Issue
                    </Button>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Button
                      className="custom-button-tab"
                      onClick={() => setActiveTab("Upload Receipt")}
                      style={{
                        backgroundColor:
                          activeTab === "Upload Receipt" ? "#0075c1" : "#fff",
                        color:
                          activeTab === "Upload Receipt" ? "#fff" : "#0075c1",
                      }}
                    >
                      Upload Receipt
                    </Button>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Button
                      className="custom-button-tab"
                      onClick={() => setActiveTab("Status")}
                      style={{
                        backgroundColor:
                          activeTab === "Status" ? "#0075c1" : "#fff",
                        color: activeTab === "Status" ? "#fff" : "#0075c1",
                      }}
                    >
                      Status
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Jumbotron>
        </Col>
        {broadcastMessage &&
          broadcastMessage.map((broadcast) => (
            <FlasMessage
              message={broadcast.message}
              date={broadcast?.date_sent}
            />
          ))}
      </Row>
      <p className="d-block d-md-none text-center font-weight-bold">
        {" "}
        Property {propertyDetails?.property_code} -{" "}
        {propertyDetails?.property_address} | {roomDetails?.room_code}
      </p>

      <Row className="info-container mt-4" style={{ maxWidth: "90%" }}>
        {activeTab === "Report Issue" ? (
          <ReportIssue />
        ) : activeTab === "Status" ? (
          <Status />
        ) : activeTab === "Upload Receipt" ? (
          <UploadReceipt />
        ) : (
          <Row>
            <Col xl={4} lg={6} md={12} xs={12} sm={12}>
              {jpsBill !== undefined ? (
                <PayBills
                  address={propertyDetails?.property_address}
                  accountNumber={jpsBill?.account_number || ""}
                  accountName={jpsBill?.account_name || ""}
                  balanceDue={jpsBill?.balance_due || ""}
                  split={split}
                  meterNumber={jpsBill?.meter_number}
                  dueDate={formatDateToLocal(jpsBill?.bill_dueDate)}
                  logo={jps_logo || null}
                  blob={jpsBill?.bill_file || null}
                  billingCycle={{
                    cycle_from: jpsBill?.cycle_from,
                    cycle_to: jpsBill?.cycle_to,
                  }}
                />
              ) : (
                <Card className="bill-card">
                  <Jumbotron>
                    <h1 className="display-4">Notice!</h1>
                    <p className="lead">
                      The{" "}
                      <span className="font-weight-bold text-warning">
                        JPS Bill
                      </span>{" "}
                      for the upcoming month has not yet been uploaded. As soon
                      as it is done you will be able to view all the details
                      here
                    </p>
                    <hr className="my-2" />
                    <p>Thank You For Your Patience!</p>
                  </Jumbotron>
                </Card>
              )}
            </Col>
            <Col
              xl={4}
              lg={6}
              md={12}
              xs={12}
              sm={12}
              className="nwc-container"
            >
              {nwcBill !== undefined ? (
                <PayBills
                  address={propertyDetails?.property_address}
                  accountNumber={nwcBill?.account_number}
                  accountName={jpsBill?.account_name || ""}
                  balanceDue={nwcBill?.balance_due}
                  meterNumber={nwcBill?.meter_number}
                  split={split}
                  dueDate={formatDateToLocal(nwcBill?.bill_dueDate)}
                  logo={nwc_logo}
                  billType="NWC"
                  billingCycle={{
                    cycle_from: nwcBill?.cycle_from,
                    cycle_to: nwcBill?.cycle_to,
                  }}
                  blob={nwcBill?.bill_file}
                />
              ) : (
                <Card className="bill-card">
                  <Jumbotron>
                    <h1 className="display-4">Notice!</h1>
                    <p className="lead">
                      The{" "}
                      <span className="font-weight-bold text-primary">
                        NWC Bill
                      </span>{" "}
                      for the upcoming month has not yet been uploaded. As soon
                      as it is done you will be able to all the details here
                    </p>
                    <hr className="my-2" />
                    <p>Thank You For Your Patience!</p>
                  </Jumbotron>
                </Card>
              )}
            </Col>
            <Col
              xl={4}
              md={12}
              lg={6}
              xs={12}
              sm={12}
              className="nwc-container mt-lg-3 mt-xl-0"
            >
              {internetBill !== undefined ? (
                <PayBills
                  address={propertyDetails?.property_address}
                  accountNumber={internetBill?.account_number}
                  balanceDue={internetBill?.balance_due}
                  meterNumber={internetBill?.meter_number}
                  split={split}
                  dueDate={formatDateToLocal(internetBill?.bill_dueDate)}
                  logo={
                    internetBill?.account_number[0] === "1"
                      ? digi_logo
                      : flow_logo
                  }
                  billType="Internet"
                  blob={internetBill?.bill_file}
                />
              ) : (
                <Card className="bill-card">
                  <Jumbotron>
                    <h1 className="display-4">Notice!</h1>
                    <p className="lead">
                      The{" "}
                      <span className="font-weight-bold text-primary">
                        Internet Bill
                      </span>{" "}
                      for the upcoming month has not yet been uploaded. As soon
                      as it is done you will be able to all the details here
                    </p>
                    <hr className="my-2" />
                    <p>Thank You For Your Patience!</p>
                  </Jumbotron>
                </Card>
              )}
            </Col>
          </Row>
        )}
      </Row>
    </Row>
  );
}
