import React, { useState } from "react";
import {
  Form,
  Input,
  InputGroup,
  InputGroupText,
  UncontrolledAlert,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import logo from "./../../assets/img/zenith-logo.jpeg";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "./ResetPassword.css";
import AuthNetworkLayer from "../../helpers/auth";
import Swal from "sweetalert2";
import { setLoading, setLoadingMessage } from "../../globals/application";
import { useDispatch } from "react-redux";

const { useLocation } = require("react-router-dom");

export default function ResetPassword() {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  ///Check if passwords match and are not empty and are at least 8 characters long and contain at least one number  and one special character
  const validatePassword = () => {
    if (
      password === confirmPassword &&
      password.length >= 8 &&
      password.match(/[!@#$%^&*()_+\-=[\]{};':"|,.<>/?]/) &&
      password.match(/[0-9]/)
    ) {
      return false;
    } else {
      return true;
    }
  };

  //function to update password
  //TODO: Add password reset functionality
  const updatePassword = async () => {
    dispatch(setLoading(true));
    dispatch(setLoadingMessage("Updating Password"));
    const token = location.search?.split("=")[1]?.split("&")[0];
    console.log("token", token);
    const user_id = location?.search?.split("=")[2];
    const params = new URLSearchParams();
    params.append("token", token);
    params.append("user_id", user_id);
    params.append("password", password);

    try {
      let result = await AuthNetworkLayer.updatePassword(params);
      if (result.success) {
        Swal.fire({
          icon: "success",
          confirmButtonColor: "#0075c1",
          title: "Password Updated",
          html: `Your password has been updated.`,
        }).then((res) => {
          if (res.isConfirmed) {
            history.push("/login");
          }
        });
        dispatch(setLoading(false));
      } else {
        Swal.fire({
          icon: "error",
          title: "An error occurred",
          text: result.message,
        }).then((res) => {
          if (res.isConfirmed) {
            history.push("/login");
          }
        });
        dispatch(setLoading(false));
      }
    } catch (e) {
      console.log("error", e);
      dispatch(setLoading(false));
    }

    //TODO: Get user_id from backend
  };

  return (
    <Form className="login-card">
      <div className="text-center">
        <img alt="logo" src={logo} width="50%" />
      </div>
      <p className="font-weight-bold text-center" style={{ fontSize: 21 }}>
        Password Reset
      </p>
      <UncontrolledAlert color="warning">
        <strong>Tip!</strong> Ensure that the password contains at least 8
        characters and contain at least one number and one special character.
      </UncontrolledAlert>

      <div className="form-group col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <label>
          New Password <span className="text-danger">*</span>
        </label>
        <InputGroup>
          <Input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type={visible ? "text" : "password"}
            className="form-control"
          />
          <InputGroupText
            className="icon-eye"
            onClick={() => setVisible(!visible)}
          >
            {visible ? <FaEye /> : <FaEyeSlash />}
          </InputGroupText>
        </InputGroup>
      </div>
      <div className="form-group col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <label>
          Confirm Password <span className="text-danger">*</span>
        </label>
        <Input
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          type={visible ? "text" : "password"}
          className="form-control"
        />
      </div>
      <div className="form-group col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center">
        <button
          disabled={validatePassword()}
          className="w-50 my-0 custom-button btn login-btn"
          type="button"
          onClick={updatePassword}
        >
          Reset Password
        </button>
      </div>
    </Form>
  );
}
