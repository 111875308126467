import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Form, Input, Label, Row } from "reactstrap";
import Swal from "sweetalert2";
import { setLoading, setLoadingMessage } from "../../globals/application";
import { setUserIdForReset } from "../../globals/auth";
import AuthNetworkLayer from "../../helpers/auth";
import logo from "./../../assets/img/zenith-logo.jpeg";
export default function Auth() {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  // function to send email
  const sendEmail = async () => {
    // send email
    const params = new URLSearchParams();
    params.append("email", email);
    dispatch(setLoading(true));
    dispatch(setLoadingMessage("Sending Email"));
    try {
      let result = await AuthNetworkLayer.resetPassword(params);
      if (result.success) {
        dispatch(setUserIdForReset(result.user_id));
        Swal.fire({
          icon: "success",
          confirmButtonColor: "#0075c1",
          title: "Email Sent",
          html: `An email has been sent to <strong style="color:#0075c1">${email}</strong> with instructions on how to reset your password.`,
        }).then((res) => {
          if (res.isConfirmed) {
            setEmail("");
          }
        });
      } else {
        dispatch(setLoading(false));
        Swal.fire({
          icon: "error",
          title: "An error occurred",
          text: result.message,
        });
      }
      dispatch(setLoading(false));
    } catch (e) {
      console.log("error", e);
      dispatch(setLoading(false));
    } finally {
      dispatch(setLoading(false));
    }
  };

  //Regex for email
  const emailRegex = RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/);

  return (
    <Form className="login-card">
      <div className="text-center">
        <img alt="logo" src={logo} width="50%" />
      </div>

      <Row>
        <Col lg={12} md={12} xs={12} sm={12} style={{ marginTop: 30 }}>
          <Label for="email">
            Email <span style={{ fontWeight: "bold", color: "red" }}>*</span>
          </Label>
          <Input
            style={{ backgroundColor: "#EBEBEB" }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            id="id"
            required
          />
        </Col>
        <Col lg={12} md={12} xs={12} sm={12} className="mt-3 mb-4"></Col>

        <Col lg={12} md={12} xs={12} sm={12} style={{ textAlign: "center" }}>
          <button
            onClick={sendEmail}
            disabled={email === "" || !emailRegex.test(email)}
            className="w-50 my-0 custom-button btn login-btn"
            type="button"
          >
            Reset Password
          </button>
          <div className="text-center small font-weight-bold mt-3">
            <Link to="/login">Back to login</Link>
          </div>
        </Col>
      </Row>
    </Form>
  );
}
