// import logo from './logo.svg';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "./App.scss";
import Login from "./components/Login";
import Register from "./components/Register";
import { Provider } from "react-redux";
import { store } from "./globals";
import LoadSpinner from "./components/misc/LoadSpinner";
import DashboardProtected from "./pages/DashboardProtected";
import NavComponent from "./components/NavComponent/";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import NotFound404 from "./components/404";
import ApplicationForm from "./components/Application";
import Auth from "./components/Auth";
import AuthProtected from "./pages/AuthProtected";

let persistor = persistStore(store);
function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LoadSpinner />
        <Router>
          <div>
            <NavComponent />
            <div className="App">
              <Switch>
                <Route path="/login" component={Login} />
                <Route path="/dashboard" component={DashboardProtected} />
                <Route path="/register" component={Register} />
                <Route path="/forgot-password" component={Auth} />
                <Route path="/password" component={AuthProtected} />
                <Route path="/test" component={ApplicationForm} />
                <Route path="/404" component={NotFound404} />
                <Redirect from="/" to="/login" />
                <Redirect from="/new-password" to="/login" exact />
                <Redirect to="/404" from="*" />
              </Switch>
            </div>
          </div>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
