import React from "react";
import { useEffect } from "react";

import { useSelector } from "react-redux";
import StudentNetworkLayer from "../../../helpers/student";
import { getCurrentUser } from "../../../globals/auth";

import { getIssues, setIssues } from "../../../globals/student";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
export default function Status() {
  const dispatch = useDispatch();
  const issues = useSelector(getIssues);
  const student = useSelector(getCurrentUser);

  useEffect(() => {
    const params = new URLSearchParams();
    params.append("user_id", student.user_id);
    StudentNetworkLayer.getIssues(params, "token").then((res) =>
      dispatch(setIssues(res.issues))
    );
  }, [issues, dispatch, student?.user_id]);

  return (
    <>
      {issues.length === 0 ? (
        <>
          <p className="text-center">No Issues submitted</p>
        </>
      ) : (
        <>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col" style={{ whiteSpace: "nowrap" }}>
                  Issue Number
                </th>
                <th scope="col" style={{ whiteSpace: "nowrap" }}>
                  Date Reported
                </th>
                <th>Description</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {console.log("Issues", issues)}
              {issues?.map((item, index) => {
                console.log("Item", item);
                return (
                  <tr key={item.issue_id}>
                    <th scope="row">{item.issue_id}</th>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {format(
                        new Date(item.createdAt),
                        "dd-MMM-yyyy"
                      ).toUpperCase()}
                    </td>
                    <td>{item.issue_description}</td>
                    <td
                      className={
                        item.status === "Pending"
                          ? "text-warning"
                          : item.status === "Received"
                          ? "text-secondary"
                          : "text-primary"
                      }
                    >
                      {item.status}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
    </>
  );
}
