import React, { useState } from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { formatDateToLocal } from "./../../functions";

import "./PayBills.css";

/**
 *
 * @param {Object} props
 * @param {String} props.billType
 * @param {String} props.accountNumber
 * @param {String} props.address
 * @param {String} props.accountName
 * @param {String} props.dueDate
 * @param {Object} props.billingCycle
 * @param {String} props.balanceDue
 * @param {String} props.meterNumber
 * @param {Object} props.logo
 * @param {String} props.split
 * @param {Object} props.blob
 *
 *
 */
export default function PayBills({
  billType,
  accountNumber,
  address,
  dueDate,
  balanceDue,
  accountName,
  split,
  meterNumber,
  blob,
  billingCycle,
  logo,
}) {
  const [paymentOpts, setPaymentOpts] = useState("");
  const redirectToPayment = () => {
    if (paymentOpts === "bill-express") {
      window.open("https://www.billexpressonline.com/", "_blank");
    } else if (paymentOpts === "pay-master") {
      window.location.href = "https://paymaster-online.com/";
    } else if (paymentOpts === "jps-online") {
      window.open("https://myjps.myjps.net/", "_blank");
    } else {
      window.open("https://ipay.nwcjamaica.com/", "_blank");
    }
  };

  // const openFile = () => {
  //   let pdfWindow = window.open("");
  //   pdfWindow.document.write(
  //     `<iframe width='100%' height='100%' src="${_arrayBufferToBase64(
  //       blob.data
  //     )}"></iframe>`
  //   );
  // };
  return (
    <div className="bill-card">
      <img alt="logo" className="mx-auto" src={logo} width="50%" height="15%" />
      <Row style={{ marginTop: 20, textAlign: "left", margin: "1em auto" }}>
        <>
          <Row style={{ margin: "0 auto" }}>
            {accountName && (
              <Col lg={6} sm={6} xs={6} md={6}>
                <span
                  style={{
                    fontFamily: "Work Sans",
                    fontWeight: "600",
                    fontSize: 16,
                  }}
                  className="text-nowrap"
                >
                  {accountName}
                </span>
                <p
                  style={{
                    fontFamily: "Work Sans",
                    fontWeight: "600",
                    opacity: 0.5,
                  }}
                >
                  Account Name
                </p>
              </Col>
            )}
            <Col lg={6} sm={6} xs={6} md={6}>
              <span
                style={{
                  fontFamily: "Work Sans",
                  fontWeight: "600",
                  fontSize: 16,
                  whiteSpace: "nowrap",
                }}
              >
                {accountNumber}
              </span>
              <p
                style={{
                  fontFamily: "Work Sans",
                  fontWeight: "600",
                  opacity: 0.5,
                }}
              >
                Account Number
              </p>
            </Col>
            <Col lg={6} sm={6} xs={6} md={6}>
              <span
                style={{
                  fontFamily: "Work Sans",
                  fontWeight: "600",
                  fontSize: 16,
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {address}
              </span>
              <p
                style={{
                  fontFamily: "Work Sans",
                  fontWeight: "600",
                  opacity: 0.5,
                }}
              >
                Home Address
              </p>
            </Col>
            {billingCycle && (
              <Col lg={6} sm={6} xs={6} md={6}>
                <span
                  className="text-truncate"
                  style={{
                    fontFamily: "Work Sans",
                    fontWeight: "600",
                    fontSize: 16,
                  }}
                >
                  {`${formatDateToLocal(
                    billingCycle.cycle_from,
                    true
                  )} - ${formatDateToLocal(billingCycle.cycle_to, true)}`}
                </span>
                <p
                  style={{
                    fontFamily: "Work Sans",
                    fontWeight: "600",
                    opacity: 0.5,
                  }}
                >
                  Billing Cycle
                </p>
              </Col>
            )}
          </Row>
          <Row style={{ margin: "0 auto" }}>
            {/* <Col lg={6} sm={6} xs={6} md={6}>
              <span
                style={{
                  fontFamily: "Work Sans",
                  fontWeight: "600",
                  fontSize: 16,
                }}
              >
                {meterNumber}
              </span>
              <p
                style={{
                  fontFamily: "Work Sans",
                  fontWeight: "600",
                  opacity: 0.5,
                }}
              >
                Meter #
              </p>
            </Col> */}
          </Row>

          <Row style={{ margin: "0 auto" }}>
            <Col lg={6} sm={6} xs={6} md={6}>
              <span
                style={{
                  fontFamily: "Work Sans",
                  fontWeight: "600",
                  fontSize: 16,
                }}
              >
                {dueDate}
              </span>
              <p
                style={{
                  fontFamily: "Work Sans",
                  fontWeight: "600",
                  opacity: 0.5,
                }}
              >
                Due Date
              </p>
            </Col>
            <Col lg={6} sm={6} xs={6} md={6}>
              <span
                style={{
                  fontFamily: "Work Sans",
                  fontWeight: "600",
                  fontSize: 16,
                }}
              >
                ${balanceDue}
              </span>
              <p
                style={{
                  fontFamily: "Work Sans",
                  fontWeight: "600",
                  opacity: 0.5,
                }}
              >
                Balance Due
              </p>
            </Col>
            <Col lg={{ size: 6 }} sm={6} xs={6} md={6}>
              <span
                style={{
                  fontFamily: "Work Sans",
                  fontWeight: "600",
                  fontSize: 16,
                }}
              >
                ${(balanceDue / split).toFixed(2)}{" "}
                <span style={{ backgroundColor: "#0075c1" }} className="badge">
                  Your Payment
                </span>
              </span>
            </Col>
            {meterNumber && (
              <Col lg={{ size: 6 }} sm={6} xs={6} md={6}>
                <span
                  style={{
                    fontFamily: "Work Sans",
                    fontWeight: "600",
                    fontSize: 16,
                  }}
                >
                  {meterNumber}
                </span>
                <p
                  style={{
                    fontFamily: "Work Sans",
                    fontWeight: "600",
                    opacity: 0.5,
                  }}
                >
                  Meter Number
                </p>
              </Col>
            )}
          </Row>
        </>

        <Row style={{ margin: "0 auto" }}>
          <p
            style={{
              fontFamily: "Work Sans",
              fontWeight: "900",
              textAlign: "center",
              fontSize: 13,
            }}
          >
            Please choose the method by which you want to pay
          </p>
          <Row
            style={{ textAlign: "left", fontSize: 13, whiteSpace: "nowrap" }}
          >
            <Col
              lg={billType === "Internet" ? 6 : 4}
              md={billType === "Internet" ? 6 : 4}
              sm={billType === "Internet" ? 6 : 4}
              xs={billType === "Internet" ? 6 : 4}
            >
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    value="bill-express"
                    onChange={(e) => setPaymentOpts(e.target.value)}
                    name="radio1"
                  />{" "}
                  Bill Express
                </Label>
              </FormGroup>
            </Col>
            <Col
              lg={billType === "Internet" ? 6 : 4}
              md={billType === "Internet" ? 6 : 4}
              sm={billType === "Internet" ? 6 : 4}
              xs={billType === "Internet" ? 6 : 4}
            >
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="radio1"
                    value="pay-master"
                    onChange={(e) => setPaymentOpts(e.target.value)}
                  />{" "}
                  Pay Master
                </Label>
              </FormGroup>
            </Col>
            {billType !== "Internet" && (
              <Col lg={4} md={4} sm={14} xs={4}>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="radio1"
                      value={billType === "NWC" ? "nwc-online" : "jps-online"}
                      onChange={(e) => setPaymentOpts(e.target.value)}
                    />{" "}
                    {billType === "NWC" ? "NWC Online " : "JPS Online"}
                  </Label>
                </FormGroup>
              </Col>
            )}
          </Row>
        </Row>
        <Row style={{ margin: "1em auto" }}>
          <Col lg={12} sm={12} xs={12} md={12}>
            <Button
              className="w-100 my-0 custom-button"
              disabled={paymentOpts === ""}
              onClick={redirectToPayment}
            >
              Pay Bill
            </Button>
          </Col>
          {/* <Col lg={6} md={6} sm={6} xs={6}>
            <Button
              className="w-100 my-0 custom-button"
              style={{ backgroundColor: "#1D445E" }}
              onClick={openFile}
            >
              View Bill
            </Button>
          </Col> */}
        </Row>
      </Row>
    </div>
  );
}
