import React from "react";
import { agreementList } from "../Register/data";
import "./Agreement.scss";
import logo from "./../../assets/img/zenith-logo.jpeg";
import { Col, Row, Input, Label, FormGroup } from "reactstrap";

/**
 *
 * @param {Object} props
 * @param {()=>void} props.handleAgreement
 *  @param {()=>void} props.handleRuleAgreement
 *
 */
export default function Agreement({ handleAgreement, handleRuleAgreement }) {
  return (
    <>
      <Row>
        <Col lg={12}>
          <div className="text-center">
            <img src={logo} alt="zenith" width="40%" />
            <h4 className="font-weight-bold text-decoration-underline">
              For the period 2021 - 2022
            </h4>
          </div>
        </Col>
        <Col lg={12}>
          <ul>
            {agreementList.map((item, index) => {
              return <li key={index}>{item}</li>;
            })}
          </ul>
        </Col>
        <hr />
        <Col lg={12} md={12} sm={12} xs={12}>
          <FormGroup check>
            <Label check style={{ fontWeight: "600" }}>
              <Input type="checkbox" onChange={handleAgreement} /> I understand
              the above housing policies and agree to abide by them at all times
            </Label>
            <br />
            <Label check style={{ fontWeight: "600" }}>
              <Input onChange={handleRuleAgreement} type="checkbox" /> I
              understand I have read in its entirety and understand the “Zenith
              General Boarding Protocol”
            </Label>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}
