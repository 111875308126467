import React, { useState, useEffect, Suspense } from "react";
import { Route, Redirect, Switch } from "react-router";
import { useDispatch } from "react-redux";
import ResetPassword from "../../components/ResetPassword";
import jwt from "jsonwebtoken";

/**
 * @param {Object} props
 * @param {*} props.match
 * @param {*} props.location
 */
export default function AuthProtected({ match, location }) {
  const dispatch = useDispatch();
  const token = location?.search?.split("=")[1]?.split("&")[0];
  const id = location?.search?.split("=")[2];
  console.log("Token", token);
  console.log("Id", id);
  const [loggedIn, setLoggedIn] = useState(
    jwt.verify(token, process.env.REACT_APP_JWT_SECRET, (err, decoded) => {
      if (err) {
        return false;
      }
      return true;
    })
  );

  /** @type {[String, React.SetStateAction<React.Dispatch<String>>]} */

  const [_, setInteracted] = useState(false);
  console.log(_);
  console.log("logged in", loggedIn);

  useEffect(() => {
    //Token verification
    jwt.verify(token, process.env.REACT_APP_JWT_SECRET, (err) => {
      if (err) {
        setLoggedIn(false);
        return;
      } else {
        setLoggedIn(true);
      }
    });

    function handleInteraction() {
      setInteracted(true);
    }

    const catchRedirect = (e) => {
      e.preventDefault();
      (e || window.event).returnValue = true;
      return null;
    };

    document.addEventListener("click", handleInteraction);
    document.addEventListener("keydown", handleInteraction);
    document.addEventListener("touchstart", handleInteraction);
    if (process.env.NODE_ENV !== "development")
      window.addEventListener("beforeunload", catchRedirect);

    return () => {
      document.removeEventListener("click", handleInteraction);
      document.removeEventListener("keydown", handleInteraction);
      document.removeEventListener("touchstart", handleInteraction);
    };
  }, [dispatch, token, location]);
  return (
    <section>
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            {loggedIn && (
              <Route
                path={[`${match.path}/reset`, `${match.path}/id:token`]}
                component={ResetPassword}
              />
            )}

            {!loggedIn && <Redirect to="/login" />}
          </Switch>
        </Suspense>
      </div>
    </section>
  );
}
