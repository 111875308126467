import { Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { FormFeedback, FormGroup, Input, Label, Form } from "reactstrap";
import logo from "./../../assets/img/zenith-logo.jpeg";
import StudentNetworkLayer from "./../../helpers/student";
import Swal from "sweetalert2";
import "./Register.scss";
import withReactContent from "sweetalert2-react-content";
import { formatPhoneNumber } from "./functions";
import { initalValues, registerSchema } from "./data";
import { setLoading, setLoadingMessage } from "../../globals/application";
import { getProperties, getRooms, setRooms } from "../../globals/student";
import PropertyNetworkLayer from "../../helpers/properties";
import Modal from "../misc/Modal";
import Agreement from "../Agreement";
// import ApplicationForm from "../Application";

const mySwal = withReactContent(Swal);

export default function Register() {
  const history = useHistory();
  const dispatch = useDispatch();
  const properties = useSelector(getProperties);
  const rooms = useSelector(getRooms);
  // const [propertyId, setSelectedPropertyId]

  const handleFormSubmit = async (values) => {
    dispatch(setLoading(true));
    dispatch(setLoadingMessage("Setting up your profile"));

    const params = new URLSearchParams();
    params.append("first_name", values.firstName);
    params.append("last_name", values.lastName);
    params.append("contact", values.telephone);
    params.append("property", values.property);
    params.append("roomCode", values.room);
    params.append("email", values.email);
    params.append("password", values.password);
    let result = await StudentNetworkLayer.registerStudent(params, "token");
    if (result.success) {
      dispatch(setLoading(false));
      let response = await mySwal.fire({
        title: "Way to Go!",
        icon: "success",
        html: `<F>Your account has been successfully created.  Your Student ID is <b class="text-primary">${result?.user_id}</b></p>`,
        confirmButtonColor: "#0075c1",
        confirmButtonText: "Login",
        allowEscapeKey: false,
        allowOutsideClick: false,
        footer: `<p class="text-danger font-weight-bold">Please take note of your Student ID as it will be required to log in</p>`,
        allowEnterKey: false,
      });

      if (response.isConfirmed) {
        history.push("/login");
      } else {
        return null;
      }
    } else {
      dispatch(setLoading(false));
      mySwal.fire({
        title: "Oops",
        icon: "error",
        text: `Seems there was an issue creating your account. ${result.message}`,
        confirmButtonColor: "#0075c1",
        confirmButtonText: "Change Email",
      });
    }
    console.log(result);
    setLoading(false);
  };

  const loadRooms = async (property_id) => {
    let params = new URLSearchParams();
    params.append("property_id", property_id);
    let roomResult = await PropertyNetworkLayer.getRooms(params, "token");
    dispatch(setRooms(roomResult?.rooms));
  };

  return (
    // <ApplicationForm />
    <div className="container">
      <div className="card o-hidden border-0 shadow-lg my-5">
        <div className="card-body p-0">
          <div className="row">
            <div className="col-lg-12">
              <div className="p-5">
                <div className="text-center">
                  <img
                    src={logo}
                    alt="zenith-logo"
                    className="img-fluid"
                    width={"20%"}
                    height={"20%"}
                  />
                  <h1 className="h4 text-gray-900 mb-4 font-weight-bold">
                    Student Registration
                  </h1>
                </div>

                <Formik
                  initialValues={initalValues}
                  validationSchema={registerSchema}
                  onSubmit={handleFormSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    dirty,
                    isValid,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Modal
                        modalTitle="ZENITH BOARDING RESIDENTIAL AGREEMENT"
                        modalContent={
                          <Agreement
                            handleAgreement={handleChange("agreementConfirmed")}
                            handleRuleAgreement={handleChange("agreeToRules")}
                          />
                        }
                        primaryLabel="Continue"
                        secondaryLabel="Cancel"
                        primaryCallbackDisabled={
                          errors.agreeToRules || errors.agreementConfirmed
                        }
                      />{" "}
                      <h3 className="font-weight-bold">Personal Information</h3>
                      <div className="form-row">
                        <FormGroup className="form-group col-md-6 col-lg-6">
                          <Label htmlFor="">
                            First Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            onBlur={handleBlur("firstName")}
                            className="form-control"
                            invalid={errors.firstName && touched.firstName}
                            onChange={handleChange("firstName")}
                          />
                          {errors.firstName && touched.firstName && (
                            <FormFeedback>{errors.firstName}</FormFeedback>
                          )}
                        </FormGroup>
                        <FormGroup className="form-group col-md-6 col-lg-6">
                          <Label>
                            Last Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            onBlur={handleBlur("lastName")}
                            className="form-control"
                            invalid={errors.lastName && touched.lastName}
                            onChange={handleChange("lastName")}
                          />
                          {errors.lastName && touched.lastName && (
                            <FormFeedback>{errors.lastName}</FormFeedback>
                          )}
                        </FormGroup>
                        {/* <FormGroup className="form-group col-md-6 col-lg-6">
                          <Label htmlFor="">
                            TRN <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            onBlur={handleBlur("firstName")}
                            className="form-control"
                            invalid={errors.firstName && touched.firstName}
                            onChange={handleChange("firstName")}
                          />
                          {errors.firstName && touched.firstName && (
                            <FormFeedback>{errors.firstName}</FormFeedback>
                          )}
                        </FormGroup> */}
                        {/* <FormGroup className="form-group col-md-6 col-lg-6">
                          <Label>
                            Date Of Birth <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="date"
                            onBlur={handleBlur("lastName")}
                            className="form-control"
                            invalid={errors.lastName && touched.lastName}
                            onChange={handleChange("lastName")}
                          />
                          {errors.lastName && touched.lastName && (
                            <FormFeedback>{errors.lastName}</FormFeedback>
                          )}
                        </FormGroup> */}
                        {/* <FormGroup className="form-group col-md-6 col-lg-6">
                          <Label htmlFor="">
                            Cell 1. <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            onBlur={handleBlur("firstName")}
                            className="form-control"
                            invalid={errors.firstName && touched.firstName}
                            onChange={handleChange("firstName")}
                          />
                          {errors.firstName && touched.firstName && (
                            <FormFeedback>{errors.firstName}</FormFeedback>
                          )}
                        </FormGroup>
                        <FormGroup className="form-group col-md-6 col-lg-6">
                          <Label>
                            Cell 2{" "}
                            <span className="font-weight-normal">
                              (Optional)
                            </span>
                          </Label>
                          <Input
                            type="text"
                            onBlur={handleBlur("lastName")}
                            className="form-control"
                            invalid={errors.lastName && touched.lastName}
                            onChange={handleChange("lastName")}
                          />
                          {errors.lastName && touched.lastName && (
                            <FormFeedback>{errors.lastName}</FormFeedback>
                          )}
                        </FormGroup> */}
                        <div className="form-group col-md-6 col-lg-6 col-sm-12 col-xs-12">
                          <label>
                            Email Address <span className="text-danger">*</span>
                          </label>
                          <Input
                            type="email"
                            className="form-control"
                            invalid={errors.email && touched.email}
                            onChange={handleChange("email")}
                            placeholder="ex. john@example.com"
                            onBlur={handleBlur("email")}
                          />
                          {errors.email && touched.email && (
                            <FormFeedback>{errors.email}</FormFeedback>
                          )}
                        </div>
                        <div className="form-group col-md-6 col-lg-6 col-sm-12 col-xs-12">
                          <label>
                            Telephone Number{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Input
                            type="telephone"
                            maxLength={15}
                            placeholder="ex. 8765555555"
                            className="form-control"
                            value={formatPhoneNumber(values.telephone)}
                            invalid={errors.telephone && touched.telephone}
                            onChange={handleChange("telephone")}
                            onBlur={handleBlur("telephone")}
                          />
                          <FormFeedback>{errors.telephone}</FormFeedback>
                        </div>
                        <div className="form-group col-md-12 col-lg-12 col-sm-12 col-xs-12">
                          <label htmlFor="">
                            Property <span className="text-danger">*</span>
                          </label>
                          <Input
                            onBlur={handleBlur("property")}
                            type="select"
                            onChange={(e) => {
                              handleChange("property")(e.target.value);
                              loadRooms(e.target.value);
                            }}
                          >
                            <option value="">Choose Property</option>
                            {properties.map((property) => (
                              <option value={property?.property_id}>
                                {property?.property_code}
                              </option>
                            ))}
                          </Input>
                          {errors.property && touched.property && (
                            <FormFeedback>{errors.property}</FormFeedback>
                          )}
                        </div>

                        <div className="form-group col-md-12 col-lg-12 col-sm-12 col-xs-12">
                          <label htmlFor="">
                            Room <span className="text-danger">*</span>
                          </label>
                          <Input
                            onBlur={handleBlur("room")}
                            type="select"
                            onChange={handleChange("room")}
                          >
                            <option value="">Select Room</option>
                            {rooms?.map((room) => (
                              <option value={room?.room_code}>
                                {room?.room_code}
                              </option>
                            ))}
                          </Input>
                          {errors.room && touched.room && (
                            <FormFeedback>{errors.room}</FormFeedback>
                          )}
                        </div>

                        <div className="form-group col-md-6 col-lg-6 col-sm-12 col-xs-12">
                          <label>
                            Password <span className="text-danger">*</span>
                          </label>
                          <Input
                            onChange={handleChange("password")}
                            onBlur={handleBlur("password")}
                            type="password"
                            className="form-control"
                            invalid={errors.password && touched.password}
                          />
                          {errors.password && touched.password && (
                            <FormFeedback>{errors.password}</FormFeedback>
                          )}
                        </div>
                        <div className="form-group col-md-6 col-lg-6 col-sm-12 col-xs-12">
                          <label>
                            Confirm Password{" "}
                            <span className="text-danger">*</span>
                          </label>

                          <Input
                            onChange={handleChange("confirmPassword")}
                            onBlur={handleBlur("confirmPassword")}
                            type="password"
                            className="form-control"
                            invalid={
                              errors.confirmPassword && touched.confirmPassword
                            }
                          />

                          {console.log(errors)}
                          {errors.confirmPassword &&
                            touched.confirmPassword && (
                              <FormFeedback>
                                {errors.confirmPassword}
                              </FormFeedback>
                            )}
                        </div>
                      </div>
                      <div className="d-flex justify-content-center">
                        {console.log("Is form Dirty?", isValid)}
                        {!(Object.keys(errors).length === 2 && dirty) ? (
                          <button
                            disabled={!(dirty && isValid)}
                            className="custom-button"
                          >
                            Next
                          </button>
                        ) : (
                          <button
                            data-toggle="modal"
                            data-target="#agreementModal"
                            className="custom-button"
                          >
                            Register
                          </button>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>

                <div className="text-center small font-weight-bold mt-2">
                  Already have an Account? <Link to="/login">Login</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
