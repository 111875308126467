import axios from "./../axios";
// import axios from "axios";
export default class StudentNetworkLayer {
  /**
   * @param  {Object} data
   * @param  {String} token
   */
  static async registerStudent(data, token) {
    const endpoint = "api/student/create";

    let response = await axios.post(endpoint, data);

    return response.data;
  }

  /**
   * @param  {Object} data
   * @param  {String} token
   */
  static async reportIssue(data, token) {
    const endpoint = "api/student/report-issue";

    let response = await axios.post(endpoint, data);

    return response.data;
  }

  /**
   * @param  {Object} data
   * @param  {String} token
   */
  static async submitReceipt(data, token) {
    const endpoint = "api/student/upload-receipt";

    let response = await axios.post(endpoint, data);

    return response.data;
  }

  /**
   * @param  {Object} data
   * @param  {String} token
   */
  static async getIssues(data, token) {
    const endpoint = "api/student/get-issues";

    let response = await axios.post(endpoint, data);

    return response.data;
  }
  /**
   * @param  {Object} data
   * @param  {String} token
   */
  static async getBills(data, token) {
    const endpoint = "api/student/get-bills";

    let response = await axios.post(endpoint, data);

    return response.data;
  }

  static async getBroadcastMessage(data, token) {
    const endpoint = "api/student/get-broadcast";
    let response = await axios.post(endpoint, data);
    return response.data;
  }
}
