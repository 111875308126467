/**
 * @param  {"student"} {name
 * @param  {defaultState} initialState
 * @param  {{login:(state} reducers
 */
import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  issues: null,
  roomDetails: null,
  propertyDetails: null,
  billDetails: null,
  nwcBillDue: "",
  jpsBillDue: "",
  rooms: [],
  properties: [],
  broadcastMessage: "",
};

let studentSlice = createSlice({
  name: "student",
  initialState: defaultState,
  reducers: {
    setIssues: (state, action) => {
      state.issues = action.payload;
    },
    setPropertyDetails: (state, action) => {
      state.propertyDetails = action.payload;
    },
    setBroadcastMessage: (state, action) => {
      state.broadcastMessage = action.payload;
    },
    setBillDetails: (state, action) => {
      state.billDetails = action.payload;
    },
    setNWCBillDue: (state, action) => {
      state.nwcBillDue = action.payload;
    },
    setJPSBillDue: (state, action) => {
      state.jpsBillDue = action.payload;
    },
    setRoomDetails: (state, action) => {
      state.roomDetails = action.payload;
    },
    setRooms: (state, action) => {
      state.rooms = action.payload;
    },
    setProperties: (state, action) => {
      state.properties = action.payload;
    },
  },
});

/**
 *
 * @param {{student: defaultState}} state
 */
export const getIssues = ({ student: state }) => state.issues;

/**
 *
 * @param {{student: defaultState}} state
 */
export const getBroadcastMessage = ({ student: state }) =>
  state.broadcastMessage;

/**
 *
 * @param {{student: defaultState}} state
 */
export const getRoomDetails = ({ student: state }) => state.roomDetails;

/**
 /**
 *
 * @param {{student: defaultState}} state
 */
export const getJPSDueDate = ({ student: state }) => state.jpsBillDue;

/**
 *
 * @param {{student: defaultState}} state
 */
export const getNWCDueDate = ({ student: state }) => state.nwcBillDue;

/**

/**
 *
 * @param {{student: defaultState}} state
 */
export const getPropertyDetails = ({ student: state }) => state.propertyDetails;

/**
 *
 * @param {{student: defaultState}} state
 */
export const getBillDetails = ({ student: state }) => state.billDetails;

/**
 *
 * @param {{student: defaultState}} state
 */
export const getProperties = ({ student: state }) => state.properties;

/**
 *
 * @param {{student: defaultState}} state
 */
export const getRooms = ({ student: state }) => state.rooms;

export const {
  setIssues,
  setPropertyDetails,
  setRoomDetails,
  setProperties,
  setBillDetails,
  setJPSBillDue,
  setNWCBillDue,
  setBroadcastMessage,
  setRooms,
} = studentSlice.actions;

export default studentSlice.reducer;
