import { format } from "date-fns";
export const formatDateToLocal = (date, cycle = false) => {
  const dueDate = new Date(date);
  const dtDateOnly = new Date(
    dueDate.valueOf() + dueDate.getTimezoneOffset() * 60 * 1000
  );
  if (cycle) {
    return format(new Date(dtDateOnly || Date.now()), "MMM dd").toUpperCase();
  } else {
    return format(
      new Date(dtDateOnly || Date.now()),
      "dd MMM yyyy"
    ).toUpperCase();
  }
};
