import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { getCurrentUser } from "../../../globals/auth";
import StudentNetworkLayer from "../../../helpers/student";
import "./../ReportIssue/ReportIssue.css";
import Swal from "sweetalert2";
import { setLoading, setLoadingMessage } from "../../../globals/application";
import { internetDueDateList } from "../../constants";
import { getPropertyDetails } from "../../../globals/student";

export default function UploadReceipt() {
  const dispatch = useDispatch();
  const property = useSelector(getPropertyDetails);

  const [billType, setBillType] = useState("");
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [month, setMonth] = useState("");
  const user = useSelector(getCurrentUser);

  const getBlob = (file) => {
    setFileName(file.name);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFile(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const submitIssue = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    dispatch(setLoadingMessage("Please wait while we submit your bill"));
    try {
      const params = new URLSearchParams();
      params.append("user_id", user?.user_id);
      params.append("billType", billType);
      params.append("file", file);
      params.append(
        "date_submitted_for",
        billType === "nwc_bill"
          ? `2021-${month}-26 00:00:00`
          : billType === "jps-receipt"
          ? `2021-${month}-30 00:00:00`
          : billType === "internet-receipt"
          ? internetDueDateList(month, property.property_id)
          : billType === "rent-receipt"
          ? `2021-${month}-25 00:00:00`
          : ""
      );

      let result = await StudentNetworkLayer.submitReceipt(params, "token");

      if (result.success) {
        dispatch(setLoading(false));
        Swal.fire({
          icon: "success",
          confirmButtonColor: "#0075c1",
          title: "Receipt Submitted",
          html: `Receipt was successfully submitted: Your Submission Number is:<b> ${result.receipt_id}</b>`,
          footer: `<p class="text-danger font-weight-bold">Please take note of your Receipt Number</p>`,
        });
      } else {
        dispatch(setLoading(false));
        Swal.fire({
          icon: "error",
          title: "Receipt Submission Failed",
          text: result.message,
        });
      }
    } catch (error) {
      dispatch(setLoading(false));
      Swal.fire({
        icon: "error",
        title: "Receipt Submission Failed",
        text: error.message,
      });
    } finally {
      dispatch(setLoading(false));
    }
  };
  return (
    <div className="issue-container">
      <Form>
        <p
          className="lead text-center"
          style={{ fontWeight: "bold", fontFamily: "Work Sans", fontSize: 24 }}
        >
          Upload Receipt
        </p>
        <Row>
          <Col lg={12} md={12} xs={12} sm={12}>
            <Label className="issue-label" htmlFor="full_name">
              Choose the type of receipt you are uploading{" "}
            </Label>

            <Col lg={12} style={{ textAlign: "left", fontSize: 13 }}>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    value="rent-receipt"
                    name="radio1"
                    onChange={(e) => setBillType(e.target.value)}
                  />{" "}
                  Rent
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    value="internet-receipt"
                    name="radio1"
                    onChange={(e) => setBillType(e.target.value)}
                  />{" "}
                  Internet
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    value="gardening-receipt"
                    name="radio1"
                    onChange={(e) => setBillType(e.target.value)}
                  />{" "}
                  Gardening
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="radio1"
                    value="jps-receipt"
                    onChange={(e) => setBillType(e.target.value)}
                  />{" "}
                  JPS
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="radio1"
                    value="nwc-bill"
                    onChange={(e) => setBillType(e.target.value)}
                  />{" "}
                  NWC
                </Label>
              </FormGroup>
            </Col>
          </Col>

          <Col lg={12} className="mt-2">
            <Label className="issue-label" htmlFor="month">
              Month Submitting For <span className="text-danger">*</span>
            </Label>
            <select
              className="form-control"
              id="month"
              onChange={(e) => setMonth(e.target.value)}
            >
              <option value="">Select Month</option>
              <option value="01">January</option>
              <option value="02">Feburary</option>
              <option value="03">March</option>
              <option value="04">April</option>
              <option value="05">May</option>
              <option value="06">June</option>
              <option value="07">July</option>
              <option value="08">August</option>
              <option value="09">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </Col>

          <Col lg={12} className="mt-2">
            <FormGroup style={{ height: "50%" }} className="mt-5">
              <Label className="issue-label" for="message">
                Upload Image{" "}
              </Label>
              <Row>
                <Col lg={8} sm={12}>
                  <Input
                    type="text"
                    name="text"
                    disabled
                    value={fileName}
                    placeholder="Upload Image"
                    style={{ backgroundColor: "#EBEBEB" }}
                  />
                </Col>
                <Col lg={4} sm={12}>
                  <Input
                    type="file"
                    hidden
                    id="upload"
                    accept="image/*"
                    onChange={(e) => getBlob(e.target.files[0])}
                    onClick={(e) => (e.target.value = null)}
                  />
                  <label
                    className=" font-weight-normal custom-button text-center w-100 upload-btn"
                    htmlFor="upload"
                  >
                    File Upload
                  </label>
                </Col>
              </Row>
            </FormGroup>
          </Col>
          <Col
            lg={12}
            md={12}
            xs={12}
            sm={12}
            style={{ textAlign: "center", marginTop: 50 }}
          >
            <Button
              disabled={file === null || billType === "" || month === ""}
              onClick={submitIssue}
              className="w-50 my-0 custom-button"
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
