import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";

export const ParentGuardianInformation = () => {
  return (
    <Row className="shadow p-3 mb-5 bg-white rounded">
      <h3>Parent/Guardian Infomration</h3>
      <FormGroup>
        <Label>Full Name</Label>
        <Input
          className="form-control mb-3"
          type="text"
          placeholder="Ex. John Doe"
        />
      </FormGroup>

      <Col lg={6} md={6} sm={12}>
        <FormGroup>
          <Label>Home Phone</Label>
          <Input
            className="form-control mb-3"
            type="tel"
            placeholder="Home Phone"
          />
        </FormGroup>
      </Col>

      <Col lg={6} md={6} sm={12}>
        <FormGroup>
          <Label>Cell. 1</Label>
          <Input className="form-control mb-3" type="tel" />
        </FormGroup>
      </Col>
      <Col lg={6} md={6} sm={12}>
        <FormGroup>
          <Label>Cell. 2 </Label>
          <Input className="form-control mb-3" type="tel" />
        </FormGroup>
      </Col>
      <FormGroup>
        <Label>TRN </Label>
        <Input className="form-control mb-3" type="number" maxLength={9} />
      </FormGroup>
      <Col lg={6}>
        <FormGroup>
          <Label>ID Number</Label>
          <Input className="form-control mb-3" type="number" maxLength={9} />
          <Row>
            <Col>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="radio1" /> Voter's ID
                </Label>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="radio1" /> School ID
                </Label>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup check disabled>
                <Label check>
                  <Input type="radio" name="radio1" disabled /> ID
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </FormGroup>
      </Col>
      <Col lg={6}>
        <Label>Email Address</Label>
        <Input type="email" />
      </Col>
    </Row>
  );
};
