import React, { useState } from "react";
import { Alert, Col, Form, Input, Label, Row } from "reactstrap";
import "./Login.css";
import logo from "./../../assets/img/zenith-logo.jpeg";
// import { useHistory } from "react-router-dom";
import AuthNetworkLayer from "../../helpers/auth";
import { useDispatch } from "react-redux";
import { setLoading, setLoadingMessage } from "../../globals/application";
import { useHistory } from "react-router";
import { login, logoutUser, setCurrentUser } from "../../globals/auth";
import { Link } from "react-router-dom";
import StudentNetworkLayer from "../../helpers/student";
import {
  setIssues,
  setProperties,
  setPropertyDetails,
  setRoomDetails,
  setBillDetails,
  setBroadcastMessage,
} from "../../globals/student";
import PropertyNetworkLayer from "../../helpers/properties";
import { useEffect } from "react";

export default function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const validateLogin = async (e) => {
    e.preventDefault();

    dispatch(setLoading(true));
    dispatch(setLoadingMessage("Logging You In"));

    try {
      const params = new URLSearchParams();
      params.append("user_id", id);
      params.append("password", password);

      let result = await AuthNetworkLayer.loginStudent(params, "token");
      if (!result.success) {
        dispatch(setLoading(false));
        setError(true);
      } else {
        let params = new URLSearchParams();
        const { user_id, propertyPropertyId, roomRoomCode } = result.student;
        params.append("user_id", user_id);
        params.append("property_id", propertyPropertyId);

        let issuesResult = await StudentNetworkLayer.getIssues(params, "token");
        let roomResult = await PropertyNetworkLayer.getRooms(params, "token");
        let propertyResult = await PropertyNetworkLayer.getProperties();
        let billsResult = await StudentNetworkLayer.getBills(params, "token");
        let broadcastMessage = await StudentNetworkLayer.getBroadcastMessage(
          params,
          "token"
        );
        dispatch(setLoading(false));
        dispatch(setCurrentUser(result.student));
        dispatch(setBroadcastMessage(broadcastMessage?.broadcast));
        dispatch(login());
        dispatch(setBillDetails(billsResult));
        dispatch(
          setRoomDetails(
            roomResult?.rooms?.find((el) => el.room_code === roomRoomCode)
          )
        );

        dispatch(
          setPropertyDetails(
            propertyResult.find((el) => el.property_id === propertyPropertyId)
          )
        );
        dispatch(setIssues(issuesResult.issues));
        setError(false);
        history.push("/dashboard");
      }
    } catch (error) {
      dispatch(setLoading(false));
      setError(true);
      setErrorMessage(
        `${error.message}, Please try again later or contact us at (876) 830-3733`
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

  const loadData = async () => {
    let properties = await PropertyNetworkLayer.getProperties();
    dispatch(setProperties(properties));
  };

  useEffect(() => {
    dispatch(logoutUser());
  }, [dispatch]);
  return (
    <div className="login">
      {error && (
        <Alert color="danger">
          &bull;{" "}
          {errorMessage ||
            "Ooops! Either your User ID or Password is Incorrect"}
        </Alert>
      )}
      <Form className="login-card">
        <div className="text-center">
          <img alt="logo" src={logo} width="50%" />
        </div>
        <Row>
          <Col lg={12} md={12} xs={12} sm={12} style={{ marginTop: 30 }}>
            <Label for="id">
              User ID{" "}
              <span style={{ fontWeight: "bold", color: "red" }}>*</span>
            </Label>
            <Input
              style={{ backgroundColor: "#EBEBEB" }}
              value={id}
              maxLength={9}
              onChange={(e) => setId(e.target.value)}
              type="text"
              id="id"
              placeholder="123456789"
              required
            />
          </Col>
          <Col lg={12} md={12} xs={12} sm={12} className="mt-3 mb-4">
            <Label for="password">
              Password{" "}
              <span style={{ fontWeight: "bold", color: "red" }}>*</span>
            </Label>
            <Input
              style={{ backgroundColor: "#EBEBEB" }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              id="password"
              required
            />
            <Link to="/forgot-password">Forgot Password?</Link>
          </Col>
          <Col lg={12} md={12} xs={12} sm={12} style={{ textAlign: "center" }}>
            <button
              onClick={validateLogin}
              disabled={password === ""}
              className="w-50 my-0 custom-button btn login-btn"
            >
              Login
            </button>
            <div className="text-center small font-weight-bold mt-3">
              Not Yet Registered?{" "}
              <Link onClick={loadData} to="/register">
                Sign Up
              </Link>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
