import axios from "./../axios";
// import axios from "axios";
export default class AuthNetworkLayer {
  /**
   * @param  {Object} data
   * @param  {String} token
   */
  static async loginStudent(data, token) {
    const endpoint = "auth/login";

    let response = await axios.post(endpoint, data);

    return response.data;
  }
  // function to reset password
  static async resetPassword(data, token) {
    const endpoint = "email/reset-password";

    let response = await axios.post(endpoint, data);

    return response.data;
  }
  // function to update password
  static async updatePassword(data, token) {
    const endpoint = "auth/update-password";

    let response = await axios.post(endpoint, data);

    return response.data;
  }
}
