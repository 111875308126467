import React, { useState } from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  UncontrolledDropdown,
} from "reactstrap";
import {
  getAuthState,
  getCurrentUser,
  getToken,
  logoutUser,
} from "../../globals/auth";
import { getPropertyDetails, getRoomDetails } from "../../globals/student";
import "./NavComponent.css";
const NavContainer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const user = useSelector(getCurrentUser);
  const propertyDetails = useSelector(getPropertyDetails);
  const roomDetails = useSelector(getRoomDetails);
  const dispatch = useDispatch();
  const toggle = () => setIsOpen(!isOpen);
  const isLoggedIn = useSelector(getAuthState);
  const token = useSelector(getToken);
  console.log("Logged", !isLoggedIn && token);
  return (
    <>
      {isLoggedIn ? (
        <div style={!isLoggedIn ? { display: "none" } : {}}>
          <div>
            <Navbar
              color="light"
              light
              expand="md"
              style={{
                minWidth: 500,
                paddingLeft: 25,
                paddingRight: 25,
                height: 50,
              }}
            >
              <NavbarBrand className="d-none d-md-block">
                {propertyDetails?.property_code} -{" "}
                {propertyDetails?.property_address} | {roomDetails?.room_code}
              </NavbarBrand>
              <NavbarToggler onClick={toggle} />
              <Collapse isOpen={isOpen} navbar>
                <Nav navbar style={{ marginLeft: "auto" }}>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      Hi, {user?.first_name}
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem onClick={() => dispatch(logoutUser())}>
                        Logout
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
              </Collapse>
            </Navbar>
          </div>
        </div>
      ) : (
        <Fragment />
      )}
    </>
  );
};

export default NavContainer;
