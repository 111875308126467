import React from "react";

/**
 *
 * @param {Object} props
 * @param {String} props.modalTitle
 * @param {Boolean=} props.primaryCallbackDisabled
 * @param {JSX|Element} props.modalContent
 * @param {()=>void=} props.primaryCallback
 * @param {()=>void=} props.secondaryCallback
 * @param {String} props.primaryLabel
 * @param {String} props.secondaryLabel
 */
export default function Modal({
  modalTitle,
  modalContent,
  primaryCallback,
  secondaryCallback,
  primaryLabel,
  secondaryLabel,
  primaryCallbackDisabled = false,
}) {
  return (
    <div
      className="modal fade"
      id="agreementModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document" style={{ maxWidth: 600 }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title font-weight-bold" id="exampleModalLabel">
              {modalTitle}
            </h5>
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">{modalContent}</div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger custom-button-danger"
              data-dismiss="modal"
              onClick={
                secondaryCallback ? () => secondaryCallback() : () => null
              }
            >
              {secondaryLabel}
            </button>
            <button
              type="submit"
              onClick={primaryCallback ? () => primaryCallback() : () => null}
              disabled={primaryCallbackDisabled}
              className="custom-button"
              data-dismiss="modal"
            >
              {primaryLabel}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
