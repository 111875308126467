import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { getCurrentUser } from "../../../globals/auth";
import StudentNetworkLayer from "../../../helpers/student";
import "./ReportIssue.css";
import Swal from "sweetalert2";
import { setLoading, setLoadingMessage } from "../../../globals/application";
import { getPropertyDetails } from "../../../globals/student";

export default function ReportIssue() {
  const dispatch = useDispatch();

  const [description, setDescription] = useState("");
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const user = useSelector(getCurrentUser);
  const propertyDetail = useSelector(getPropertyDetails);

  const getBlob = (file) => {
    setFileName(file.name);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFile(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const submitIssue = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    dispatch(setLoadingMessage("Please wait while we submit your issue"));

    try {
      const params = new URLSearchParams();
      params.append("user_id", user?.user_id);
      params.append("description", description);
      params.append("file", file);
      let result = await StudentNetworkLayer.reportIssue(params, "token");

      if (result.success) {
        dispatch(setLoading(false));
        Swal.fire({
          icon: "success",
          confirmButtonColor: "#0075c1",
          title: "Issue Submitted",
          html: `Issue was successfully submitted: Your Issue Number is:<b> ${result.issue_id}</b>`,
          footer: `<p class="text-danger font-weight-bold">Please take note of your issue number</p>`,
        }).then((res) => {
          if (res.isConfirmed) {
            setFile("null");
            setFileName("");
            setDescription("");
          }
        });
      } else {
        dispatch(setLoading(false));
        Swal.fire({
          icon: "error",
          title: "Issue Submission Failed",
          text: result.message,
        });
      }
    } catch (err) {
      console.log(err);
      dispatch(setLoading(false));
    } finally {
      dispatch(setLoading(false));
    }
  };
  return (
    <div className="issue-container">
      <Form>
        <p
          className="lead text-center"
          style={{ fontWeight: "bold", fontFamily: "Work Sans", fontSize: 24 }}
        >
          Report Issue Form
        </p>

        <Row>
          <Col lg={12} md={12} xs={12} sm={12}>
            <FormGroup>
              <Label className="issue-label" htmlFor="full_name">
                Full Name{" "}
                <span style={{ fontWeight: "bold", color: "red" }}>*</span>
              </Label>
              <Input
                value={`${user?.first_name} ${user?.last_name}`}
                style={{ backgroundColor: "#EBEBEB" }}
                type="text"
                disabled
                id="full_name"
                placeholder="John Doe"
              />
            </FormGroup>
          </Col>
          <Col lg={12} md={12} xs={12} sm={12} className="mt-2">
            <FormGroup>
              <Label className="issue-label" htmlFor="property_name">
                Property Name{" "}
                <span style={{ fontWeight: "bold", color: "red" }}>*</span>
              </Label>
              <Input
                value={propertyDetail?.property_code}
                type="text"
                required
                disabled
                id="property_name"
                placeholder="Ex. Property Z"
                style={{ backgroundColor: "#EBEBEB" }}
              />
            </FormGroup>
          </Col>
          <Col lg={12} className="mt-2">
            <FormGroup>
              <Label className="issue-label" for="message">
                Comment{" "}
                <span style={{ fontWeight: "bold", color: "red" }}>*</span>
              </Label>
              <Input
                type="textarea"
                name="text"
                value={description}
                required
                id="textarea"
                placeholder="Describe the issue..."
                style={{ backgroundColor: "#EBEBEB", height: 155 }}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col lg={12} className="mt-2">
            <FormGroup style={{ height: "50%" }} className="mt-5">
              <Label className="issue-label" for="message">
                Upload Image{" "}
              </Label>
              <Row>
                <Col lg={8} sm={12}>
                  <Input
                    type="text"
                    name="text"
                    disabled
                    value={fileName}
                    placeholder="Upload Image"
                    style={{ backgroundColor: "#EBEBEB" }}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Col>
                <Col lg={4} sm={12}>
                  <Input
                    type="file"
                    hidden
                    id="upload"
                    accept="image/*"
                    onChange={(e) => getBlob(e.target.files[0])}
                    onClick={(e) => (e.target.value = null)}
                  />
                  <label
                    className="custom-button text-center w-100 upload-btn"
                    htmlFor="upload"
                  >
                    Upload <i className="fas fa-upload" />
                  </label>
                </Col>
              </Row>
            </FormGroup>
          </Col>
          <Col
            lg={12}
            md={12}
            xs={12}
            sm={12}
            style={{ textAlign: "center", marginTop: 50 }}
          >
            <Button onClick={submitIssue} className="w-50 my-0 custom-button">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
