export const internetDueDateList = (month, property) => {
  if (property === 1) {
    return `2021-${month}-09 00:00:00`;
  }

  if (property === 2) {
    return `2021-${month}-10 00:00:00`;
  }
  if (property === 3) {
    return `2021-${month}-11 00:00:00`;
  }

  if (property === 4) {
    return `2021-${month}-12 00:00:00`;
  }
  if (property === 5) {
    return `2021-${month}-13 00:00:00`;
  }

  if (property === 7) {
    return `2021-${month}-15 00:00:00`;
  }
  if (property === 8) {
    return `2021-${month}-16 00:00:00`;
  }
};
